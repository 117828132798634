import * as React from 'react';
import type { NextPage} from 'next'

export interface IPageWithUser {
}

const Home: NextPage<IPageWithUser> = (props) => {

  return (
    <div>
      Shelterz.io
    </div>
  )
}

export default Home;

